import {
    Salary,
    SalaryItemTypeEnum, useCalculateSalariesLazyQuery,
    useCalculateSalariesQuery,
    useCreateSalaryGroupMutation,
    useGetSalaryExtendsQuery
} from "../generated/graphql";
import {AppBar, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from "@mui/material";
import theme from "../theme/Theme";
import React, {useCallback, useContext, useEffect, useState} from "react";
import TabPanel from "../component/TabPanel";
import {DataGridLocale, DateValueGetter, DefaultValueGetter, useTabIndex} from "../common/Utils";
import {filter, omit, pick, reduce, sortBy} from "lodash";
import FormRenderer, {InputType} from "../common/FormRenderer";
import {Formik} from "formik";
import {useCompany, useEmployeeList} from "../auth/Auth";
import {WorkTypeMap} from "../common/Constant";
import {DataGridPro, GridValueGetterParams} from "@mui/x-data-grid-pro";
import {format} from "date-fns";
import NotificationPopup from "../common/NotificationPopup";
import {CompanyContext} from "../routes";
// const columns:any = [
//     {name: "yearMonth", label: "薪資年月", value: "202207", type: "month", required: true },
//     {name: "title", label: "薪資發放名稱", value: "TEST", required: true },
//     {name: "startDate", label: "起始日", type: InputType.date, required: true },
//     {name: "endDate", label: "結束日", type: InputType.date, required: true },
//     {name: "payDate", label: "發薪日", type: InputType.date, required: true },
// ];
const columns: any = [
    {name: "yearMonth", label: "薪資年月", type: "month"},
    {name: "title", label: "薪資發放名稱"},
    {name: "startDate", label: "起始日", type: InputType.date},
    {name: "endDate", label: "結束日", type: InputType.date},
    {name: "payDate", label: "發薪日", type: InputType.date},
];
const staticSalaryColumns = [
    {field: "id", headerName: "ID", width: 80, hide: true},
    {field: "code", headerName: "員工編號", width: 100, valueGetter: DefaultValueGetter},
    {field: "name", headerName: "姓名", width: 100, valueGetter: DefaultValueGetter},
    {field: "arrivalDate", headerName: "到職日", width: 100, valueGetter: DateValueGetter},
    {field: "leaveDate", headerName: "離職日", width: 100, valueGetter: DateValueGetter},
    {field: "type", headerName: "計薪方式", width: 100, valueGetter: params => WorkTypeMap[params.value]}
]
export default () =>
{
    const [open, setOpen] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [salaryGroup, setSalaryGroup] = useState<any>({});
    const {
        loading: salaryExtendsLoading,
        data: salaryExtendsData,
        error: salaryExtendsError
    } = useGetSalaryExtendsQuery();
    const [salaryColumns, setSalaryColumns] = useState<any>(staticSalaryColumns);
    const [salaryRows, setSalaryRows] = useState<any>([]);
    const [salaryTypeMap, setSalaryTypeMap] = useState({});
    const [createSalaryGroup] = useCreateSalaryGroupMutation();
    const company = useContext(CompanyContext);
    const [calculateSalariesQuery, {loading, data}] = useCalculateSalariesLazyQuery();

    const onCreate = async () =>
    {
        try {
            await createSalaryGroup({
                variables: {
                    salaryGroup:
                        {
                            ...pick(salaryGroup, ["companyId", "yearMonth", "title", "startDate", "endDate", "payDate"]),
                            companyId: company?.id,
                            salaries: salaryRows?.map((salaryRow) =>
                            {
                                return {
                                    employeeId: salaryRow.id, salaryItems:
                                        filter(Object.keys(salaryTypeMap).map((salaryTitle) =>
                                        {
                                            return {
                                                title: salaryTitle,
                                                amount: salaryRow[salaryTitle],
                                                type: salaryTypeMap[salaryTitle]
                                            }
                                        }), (row) => row?.amount)
                                }
                            }),
                        }
                },
                refetchQueries: ["getSalaryGroups", "getSalaries"],
            })
            NotificationPopup.success(`新增完成`);
        } catch (e: any) {
            NotificationPopup.error(
                `發生問題：${e.message}`
            );
            console.error(e);
        }
    }
    useEffect(() =>
    {
        if (data == null) return;
        let salaryColumns: any = staticSalaryColumns;
        const salaryTypeMap = {};
        setSalaryRows(data?.calculateSalaries?.map((salary) =>
        {
            let salaryRow = pick(salary.employee, ['id', 'code', 'name', 'arrivalDate', 'leaveDate', 'type']);
            salary.salaryItems?.map((salaryItem) =>
            {
                salaryRow = {...salaryRow, [salaryItem.title as string]: salaryItem.amount};
                if (!salaryTypeMap.hasOwnProperty(salaryItem.title as string)) {
                    salaryTypeMap[salaryItem.title as string] = salaryItem.type;
                    salaryColumns = [...salaryColumns, {
                        field: salaryItem.title,
                        headerName: salaryItem.title,
                        width: 120,
                        editable: true,
                        valueGetter: params => params.value ?? 0,
                    }];
                }
            });
            return salaryRow;
        }));
        setSalaryTypeMap(salaryTypeMap);
        setSalaryColumns([...sortBy([...salaryColumns, {
            field: "salaryItems.plus",
            headerName: "應發金額",
            width: 120,
            valueGetter: (params: GridValueGetterParams) =>
            {
                return reduce(Object.keys(omit(params?.row, ['id', 'code', 'name', 'arrivalDate', 'leaveDate', 'type'])),
                    (acc, key) =>
                    {
                        return acc + ([SalaryItemTypeEnum.Normal, SalaryItemTypeEnum.Plus].includes(salaryTypeMap[key]) ? parseInt(params?.row[key]) : 0);
                    }, 0);
            }
        },
            {
                field: "salaryItems.minus",
                headerName: "應減金額",
                width: 120,
                valueGetter: (params: GridValueGetterParams) =>
                {
                    return reduce(Object.keys(omit(params?.row, ['id', 'code', 'name', 'arrivalDate', 'leaveDate', 'type'])),
                        (acc, key) =>
                        {
                            return acc + (salaryTypeMap[key] == SalaryItemTypeEnum.Minus ? parseInt(params?.row[key]) : 0);
                        }, 0);
                }
            },
            {
                field: "salaryItems.total",
                headerName: "實發金額",
                width: 120,
                valueGetter: (params: GridValueGetterParams) =>
                {
                    return reduce(Object.keys(omit(params?.row, ['id', 'code', 'name', 'arrivalDate', 'leaveDate', 'type'])),
                        (acc, key) =>
                        {
                            return acc + (salaryTypeMap[key] == SalaryItemTypeEnum.Minus ? -1 : salaryTypeMap[key] == SalaryItemTypeEnum.Company ? 0 : 1) * parseInt(params?.row[key]);
                        }, 0);
                }
            },], (column) =>
        {
            switch (salaryTypeMap[column.field]) {
                case "normal":
                    return 1;
                case "plus":
                    return 2;
                case "minus":
                    return 3;
                case "company":
                    return 5;
                default:
                    if (column.field.includes("salaryItems")) {
                        return 4;
                    }
                    return 0;
            }
        }),

        ]);
    }, [data])
    // console.log(salaryColumns);
    return <div>
        <Dialog open={open} fullWidth={true} maxWidth={tabIndex == 1 ? "lg" : "sm"}>
            <DialogTitle style={{backgroundColor: theme.palette.primary.dark}}>
                <Typography variant="h3" align="center">
                    薪資計算
                </Typography>
            </DialogTitle>
            <DialogContent>

                <TabPanel value={tabIndex} index={0}>
                    <Formik
                        initialValues={salaryGroup}
                        onSubmit={async (values, bla) =>
                        {
                            setTabIndex(1);
                            setSalaryGroup(values);
                            await calculateSalariesQuery({
                                variables: {
                                    yearMonth: values.yearMonth, startDate: values.startDate,
                                    endDate: values.endDate, companyId: company?.id as number
                                }
                            });
                        }}>
                        {({handleChange, resetForm, handleSubmit, values}) =>
                        {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <FormRenderer
                                        values={values}
                                        def={columns}
                                        onChange={handleChange}
                                    />
                                    <DialogActions
                                        style={{paddingLeft: 24, paddingRight: 24, justifyContent: "center"}}>
                                        <Button
                                            variant={"contained"}
                                            color={"secondary"}
                                            type={"submit"}
                                        >
                                            下一步
                                        </Button>
                                        <Button
                                            variant={"contained"}
                                            color={"secondary"}
                                            onClick={() =>
                                            {
                                                setOpen(false);
                                                resetForm();
                                            }}
                                        >
                                            關閉
                                        </Button>
                                    </DialogActions>
                                </form>)
                        }}
                    </Formik>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <DialogContent>
                        <DataGridPro
                            autoHeight={true}
                            localeText={DataGridLocale}
                            experimentalFeatures={{columnGrouping: true}}
                            columnGroupingModel={[{
                                groupId: "薪資結構",
                                children: filter(salaryColumns, (salaryColumn) =>
                                {
                                    return salaryTypeMap[salaryColumn.field] == SalaryItemTypeEnum.Normal;
                                }).map((column) =>
                                {
                                    return {field: column.field}
                                })
                            }, {
                                groupId: "薪資科目加項",
                                children: filter(salaryColumns, (salaryColumn) =>
                                {
                                    return salaryTypeMap[salaryColumn.field] == SalaryItemTypeEnum.Plus;
                                }).map((column) =>
                                {
                                    return {field: column.field}
                                })
                            }, {
                                groupId: "薪資科目減項",
                                children: filter(salaryColumns, (salaryColumn) =>
                                {
                                    return salaryTypeMap[salaryColumn.field] == SalaryItemTypeEnum.Minus;
                                }).map((column) =>
                                {
                                    return {field: column.field}
                                })
                            }, {
                                groupId: "公司負擔",
                                children: filter(salaryColumns, (salaryColumn) =>
                                {
                                    return salaryTypeMap[salaryColumn.field] == SalaryItemTypeEnum.Company;
                                }).map((column) =>
                                {
                                    return {field: column.field}
                                })
                            }]}
                            columns={salaryColumns}
                            rows={salaryRows}
                            onCellEditCommit={params =>
                            {
                                setSalaryRows(salaryRows.map((salaryRow) =>
                                {
                                    if (salaryRow.id == params.id) {
                                        return {...salaryRow, [params.field]: parseInt(params?.value as string)};
                                    }
                                    return salaryRow;
                                }));
                            }}
                        />
                    </DialogContent>
                    <DialogActions style={{paddingLeft: 24, paddingRight: 24, justifyContent: "center"}}>
                        <Button
                            variant={"contained"}
                            color={"secondary"}
                            onClick={() =>
                            {
                                setTabIndex(2);
                            }}
                        >
                            計算結果
                        </Button>
                        <Button
                            variant={"contained"}
                            color={"secondary"}
                            onClick={() =>
                            {
                                setTabIndex(0);
                            }}
                        >
                            上一步
                        </Button>
                    </DialogActions>
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={5}>薪資年月：</Grid>
                            <Grid item xs={7}>{salaryGroup.yearMonth}</Grid>
                            <Grid item xs={5}>薪資發放名稱：</Grid>
                            <Grid item xs={7}>{salaryGroup.title}</Grid>
                            <Grid item xs={5}>計算區間：</Grid>
                            <Grid item xs={7}>{`${format(new Date(salaryGroup.startDate ?? null), "yyyy/MM/dd")}~
                            ${format(new Date(salaryGroup.endDate ?? null), "yyyy/MM/dd")}`}</Grid>

                            <Grid item xs={5}>發薪人數：</Grid>
                            <Grid item xs={7}>{`${data?.calculateSalaries?.length}人`}</Grid>
                            <Grid item xs={5}>應發總額：</Grid>
                            <Grid item xs={7}>{reduce(salaryRows, (aac, salaryRow) =>
                            {
                                let rowCount = 0;
                                Object.keys(salaryTypeMap).map((salaryTitle) =>
                                {
                                    const type = salaryTypeMap[salaryTitle];
                                    const multiply = ([SalaryItemTypeEnum.Normal, SalaryItemTypeEnum.Plus].includes(type)) ? 1 : 0;
                                    rowCount += (salaryRow[salaryTitle] ?? 0) * multiply;
                                })
                                return aac + rowCount;
                            }, 0).toLocaleString()}</Grid>
                            <Grid item xs={5}>應減總額：</Grid>
                            <Grid item xs={7}>{reduce(salaryRows, (aac, salaryRow) =>
                            {
                                let rowCount = 0;
                                Object.keys(salaryTypeMap).map((salaryTitle) =>
                                {
                                    const type = salaryTypeMap[salaryTitle];
                                    const multiply = type == SalaryItemTypeEnum.Minus ? 1 : 0;
                                    rowCount += (salaryRow[salaryTitle] ?? 0) * multiply;
                                })
                                return aac + rowCount;
                            }, 0).toLocaleString()}</Grid>
                            <Grid item xs={5}>實發總額：</Grid>
                            <Grid item xs={7}>{reduce(salaryRows, (aac, salaryRow) =>
                            {
                                let rowCount = 0;
                                Object.keys(salaryTypeMap).map((salaryTitle) =>
                                {
                                    const type = salaryTypeMap[salaryTitle];
                                    const multiply = type == SalaryItemTypeEnum.Minus ? -1 : type == SalaryItemTypeEnum.Company ? 0 : 1;
                                    rowCount += (salaryRow[salaryTitle] ?? 0) * multiply;
                                })
                                return aac + rowCount;
                            }, 0).toLocaleString()}</Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions style={{paddingLeft: 24, paddingRight: 24, justifyContent: "center"}}>
                        <Button
                            variant={"contained"}
                            color={"secondary"}
                            onClick={async () =>
                            {
                                await onCreate();
                                setOpen(false);
                                setTabIndex(0);
                            }}
                        >
                            確認發放
                        </Button>
                        <Button
                            variant={"contained"}
                            color={"secondary"}
                            onClick={() =>
                            {
                                setTabIndex(1);
                            }}
                        >
                            上一步
                        </Button>
                    </DialogActions>

                </TabPanel>

            </DialogContent>

        </Dialog>
        <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => setOpen(true)}
        >
            薪資計算
        </Button>
    </div>
}