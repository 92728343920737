import {Container, Box} from "@mui/material";
import {DataGridPro, GridColDef} from "@mui/x-data-grid-pro";
import DefaultQueryHandler from "../common/DefaultQueryHandler";
import Page from "../common/Page";
import {
    useCreateCompanyMutation,
    useGetCompaniesQuery,
    useRemoveCompanyMutation,
} from "../generated/graphql";
import UpdatePopupButton from "../component/UpdatePopupButton";
import {useCallback} from "react";
import NotificationPopup from "../common/NotificationPopup";
import ConfirmButton from "../component/ConfirmButton";
import {DataGridLocale} from "../common/Utils";
import UpdateCompanyButton from "./UpdateCompanyButton";

const CompanyPage = () => {
    const [createCompany] = useCreateCompanyMutation();
    const [removeCompany] = useRemoveCompanyMutation();

    const columns: GridColDef[] = [
        {field: "id", headerName: "ID", width: 100},
        {field: "name", headerName: "公司名稱", width: 200},
        {field: "taxId", headerName: "統一編號", width: 100},
        // {field: "companyAuthorizations", headerName: "勞報單結束日期", width: 150},
        // {field: "companyAuthorizations", headerName: "財務報表結束日期", width: 150},
        // {field: "companyAuthorizations", headerName: "人事管理結束日期", width: 150},
        // {field: "companyAuthorizations", headerName: "電子發票結束日期", width: 150},
        {
            field: "action", headerName: "動作", width: 150,
            renderCell: (params) => {
                return <>
                    <UpdateCompanyButton company={params.row} />&nbsp;
                    <ConfirmButton onConfirm={() => {
                        onRemove(params.row.id)
                    }}>刪除</ConfirmButton>
                </>
            }
        }
    ];
    const onCreate = useCallback(
        async (company, { resetForm }, setOpen) => {
            try {
                await createCompany({
                    variables: {
                        company: company,
                    },
                    refetchQueries: ["getCompanies"],
                });
                NotificationPopup.success(`新增成功`);
            } catch (e:any) {
                NotificationPopup.error(
                    `發生問題：${e.message}`
                );
                console.error(e);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [createCompany]
    )
    const onRemove = (id) => (
        removeCompany({
            variables: {
                id: id,
            },
            refetchQueries: ["getCompanies"]
        })
    );

    const {loading, data, error} = useGetCompaniesQuery();
    return (
        <Page title={"company list page"}>
            <Container maxWidth={false} style={{padding: "30px"}}>
                {/* <Grid container spacing={2}> */}
                {/* </Grid> */}
                <UpdatePopupButton
                    title={"建立公司"}
                    columns={[
                        {
                            name: "name",
                            label: "名稱",
                        },
                        {
                            name: "taxId",
                            label: "統一編號",
                        },
                    ]}
                    onSubmit={onCreate}
                    submitTitle={"儲存"}
                />
                <DefaultQueryHandler error={error} loading={loading}>
                    <Box
                        width={"100%"}
                        style={{marginTop: "30px", height: "100vh"}}
                    >
                        <DataGridPro
                            localeText={DataGridLocale}
                            autoHeight={true}
                            loading={loading}
                            rows={data?.companies || []}
                            columns={columns}
                        />
                    </Box>
                </DefaultQueryHandler>
            </Container>
        </Page>
    );
};
export default CompanyPage