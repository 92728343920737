import {useParams} from "react-router-dom";
import {
    EmployStateEnum,
    GenderEnum, IdentityEnum, SalaryItemTypeEnum,
    TaxClassificationEnum, useCreateEmployeeMutation,
    useGetEmployeeQuery, useUpdateEmployeeMutation,
    WorkTypeEnum
} from "../generated/graphql";
import {
    Autocomplete,
    Button,
    Card,
    Checkbox,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, FormControl, FormControlLabel,
    Grid, InputLabel,
    MenuItem, Select,
    TextField,
    Typography
} from "@mui/material";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {TaxClassificationMap} from "../common/Constant";
import {EditSelect} from "../labor-contracts/LaborContractView";
import theme from "../theme/Theme";
import {InputType} from "../common/FormRenderer";
import {filter, omit, random, reduce} from "lodash";
import NotificationPopup from "../common/NotificationPopup";
import {useUser} from "../auth/Auth";
import {format} from "date-fns";
import {CompanyContext} from "../routes";
import ConfirmButton from "../component/ConfirmButton";

const SalaryItemsOptions = [
    '基本薪資', '伙食費', '主管津貼', '其他津貼',
].map((option) => ({value: option, label: option}));

export const EditTextField = (props) =>
{
    return <TextField
        InputProps={{startAdornment: props.type == InputType.date ? <></> : null}}
        {...props}
        variant={"outlined"}
        style={{width: "100%"}}
        value={props.value || ""}
        onChange={({target}) =>
        {
            if (props.setter) {
                props.setter((props.type=="number" && target.value!=null) ? parseInt(target.value) : target.value);
            }
        }}
    />;
}
export default ({employee}: any) =>
{
    const me = useUser();
    const [open, setOpen] = useState(false);
    const [editEmployee, setEditEmployee] = useState(employee ?? {});
    const [createEmployee] = useCreateEmployeeMutation();
    const [updateEmployee] = useUpdateEmployeeMutation();
    const company = useContext(CompanyContext);
    const [pickupEnabled, setPickupEnabled] = useState(employee?.pickup > 0)

    const onChange = (fieldName) =>
    {
        return (value) =>
        {
            setEditEmployee({...editEmployee, [fieldName]: value});
        }
    };
    const onSalaryItemChange = (index, field, value) =>
    {
        setEditEmployee({
            ...editEmployee, salaryItems: editEmployee.salaryItems.map((item, itemIndex) =>
            {
                return index == itemIndex ? {...item, [field]: value} : item
            })
        })
    }
    const onDependentChange = (index, field, value) =>
    {
        setEditEmployee({
            ...editEmployee, dependents: editEmployee.dependents.map((item, itemIndex) =>
            {
                return index == itemIndex ? {...item, [field]: value} : item
            })
        })
    }
    const onCreate = useCallback(
        async ()=>{
            try {
                await createEmployee({
                    variables: {employee: {...editEmployee, companyId: company?.id}},
                    refetchQueries: ["getEmployees"],
                });
                NotificationPopup.success(`新增成功`);
            } catch (e: any) {
                NotificationPopup.error(
                    `發生問題：${e.message}`
                );
                console.error(e);
            } finally {
                setOpen(false);
            }
        },[createEmployee, editEmployee]
    );
    const onUpdate = useCallback(
        async () => {
            try {
                await updateEmployee({
                    variables: {employee: omit(editEmployee, ["id", "createdAt", "updatedAt", "company"]), id: employee.id},
                    refetchQueries: ["getEmployees"],
                });
                NotificationPopup.success(`新增成功`);
            } catch (e: any) {
                NotificationPopup.error(
                    `發生問題：${e.message}`
                );
                console.error(e);
            } finally {
                setOpen(false);
            }
        },[updateEmployee, editEmployee]
    );

    const action = employee ? "編輯" : "新增";
    const title = `${action}員工資訊`;
    return <div>
        <Dialog open={open} fullWidth={true} maxWidth={"md"}>
            <form onSubmit={async ()=> {
                if (employee) {
                    await onUpdate();
                }
                else {
                    await onCreate();
                }
            }}>
                <DialogTitle style={{backgroundColor: theme.palette.primary.dark}}>
                    <Typography variant="h4" align="center">
                        {title}
                    </Typography>
                </DialogTitle>
                <DialogContent style={{padding: 0}}>
                    <Card style={{margin: 20, padding: 20}}>
                        <Grid container direction={"row"} border={1}>
                            <Grid borderRight={1} borderBottom={1} style={{
                                background: "#fab507", display: "flex",
                                alignItems: "center", justifyContent: "center", width: 80, height: 60
                            }}>
                                基本資料
                            </Grid>
                            <Grid item container spacing={2} xs={10} padding={2}>
                                <Grid item xs={6}>
                                    <EditTextField
                                        required label={"姓名"}
                                        setter={onChange("name")}
                                        value={editEmployee?.name}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <EditTextField required label={"員工編號"}
                                                   setter={onChange("code")}
                                                   value={editEmployee?.code}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <EditSelect label={"性別"}
                                                setter={onChange("gender")}
                                                value={editEmployee?.gender}>
                                        <MenuItem value={GenderEnum.Female}>女</MenuItem>
                                        <MenuItem value={GenderEnum.Male}>男</MenuItem>
                                    </EditSelect>
                                </Grid>
                                <Grid item xs={6}>
                                    <EditTextField label={"國籍"}
                                                   setter={onChange("nationality")}
                                                   value={editEmployee?.nationality}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <EditTextField label={"生日"} type={"date"}
                                                   setter={onChange("birthday")}
                                                   value={editEmployee?.birthday ? format(new Date(editEmployee?.birthday), "yyyy-MM-dd") : null}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <EditTextField label={"身分證字號"}
                                                   setter={onChange("idNumber")}
                                                   value={editEmployee?.idNumber}/>
                                </Grid>
                                <Grid item xs={8}>
                                    <EditSelect required label={"在職狀態"}
                                                setter={onChange("state")}
                                                value={editEmployee?.state}>
                                        <MenuItem value={EmployStateEnum.Employed}>在職</MenuItem>
                                        <MenuItem value={EmployStateEnum.Pending}>留職停薪</MenuItem>
                                        <MenuItem value={EmployStateEnum.Resigned}>離職</MenuItem>
                                    </EditSelect>
                                </Grid>
                                <Grid item xs={8}>
                                    <EditTextField required label={"到職日期"} type={"date"}
                                                   setter={onChange("arrivalDate")}
                                                   value={editEmployee?.arrivalDate ? format(new Date(editEmployee?.arrivalDate), "yyyy-MM-dd") : null}/>
                                </Grid>
                                <Grid item xs={8}>
                                    <EditTextField label={"離職日期"} type={"date"}
                                                   setter={onChange("leaveDate")}
                                                   value={editEmployee?.leaveDate ? format(new Date(editEmployee?.leaveDate), "yyyy-MM-dd") : null}/>
                                </Grid>
                                <Grid item xs={8}>
                                    <EditTextField type={"email"} label={"Email"}
                                                   setter={onChange("email")}
                                                   value={editEmployee?.email}/>
                                </Grid>
                                <Grid item xs={8}>
                                    <EditTextField label={"戶籍地址"}
                                                   setter={onChange("address")}
                                                   value={editEmployee?.address}/>
                                </Grid>
                                <Grid item xs={8}>
                                    <EditTextField type={"tel"} label={"行動電話"}
                                                   setter={onChange("phone")}
                                                   value={editEmployee?.phone}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction={"row"} border={1} borderTop={0}>
                            <Grid borderRight={1} borderBottom={1} style={{
                                background: "#fab507", display: "flex",
                                alignItems: "center", justifyContent: "center", width: 80, height: 60
                            }}>
                                *薪資資料
                            </Grid>
                            <Grid item container spacing={2} xs={10} padding={2}>
                                <Grid item xs={8}>
                                    <b>薪資基本資料</b>
                                </Grid>
                                <Grid item xs={8}>
                                    <EditTextField label={"銀行代碼"}
                                                   setter={onChange("bankCode")}
                                                   value={editEmployee?.bankCode}/>
                                </Grid>
                                <Grid item xs={8}>
                                    <EditTextField label={"銀行帳號"}
                                                   setter={onChange("accountNumber")}
                                                   value={editEmployee?.accountNumber}/>
                                </Grid>
                                <Grid item xs={8}>
                                    <b>薪資結構</b>
                                </Grid>
                                <Grid item xs={8}>
                                    <EditSelect required label={"身份別"}
                                                setter={onChange("identity")}
                                                value={editEmployee?.identity}>
                                        <MenuItem value={IdentityEnum.Employee}>員工</MenuItem>
                                        <MenuItem value={IdentityEnum.Employer}>雇主</MenuItem>
                                    </EditSelect>
                                </Grid>
                                <Grid item xs={8}>
                                    <EditSelect required label={"計薪方式"}
                                                setter={onChange("type")}
                                                value={editEmployee?.type}>
                                        <MenuItem value={WorkTypeEnum.Monthly}>月薪</MenuItem>
                                        <MenuItem value={WorkTypeEnum.Hourly}>時薪</MenuItem>
                                    </EditSelect>
                                </Grid>
                                <Grid item xs={2} alignItems={"center"} display={"flex"}> <Button
                                    variant={"contained"}
                                    color={"primary"}
                                    onClick={() =>
                                    {
                                        const newItem = {
                                            title: '基本薪資',
                                            amount: 0,
                                            type: SalaryItemTypeEnum.Normal,
                                        };
                                        const newSalaryItems = editEmployee.salaryItems ? [...editEmployee.salaryItems, newItem] : [newItem];
                                        setEditEmployee({...editEmployee, salaryItems: newSalaryItems});
                                    }}
                                >新增</Button></Grid>
                                <Grid item xs={8}>
                                    {editEmployee.salaryItems?.map((item, index) =>
                                    {
                                        return <Grid container spacing={2} margin={1} alignItems={"center"}>
                                            <Grid item xs={4}><Autocomplete
                                                freeSolo
                                                value={item.title}
                                                options={SalaryItemsOptions}
                                                renderInput={(params) => (
                                                    <TextField required label={"薪資名目"} {...params}
                                                               onChange={({target}) =>
                                                               {
                                                                   onSalaryItemChange(index, "title", target?.value);
                                                               }}
                                                    />)}
                                                onChange={(_, option) =>
                                                {
                                                    onSalaryItemChange(index, "title", option?.value);
                                                }}
                                            /></Grid>
                                            <Grid item xs={6}><TextField required type={"number"} label={"金額"}
                                                                         value={item.amount?.toString()} onChange={({target}) =>
                                            {
                                                onSalaryItemChange(index, "amount", parseInt(target?.value));
                                            }}/></Grid>
                                            <Grid item xs={1}><Button
                                                variant={"contained"}
                                                color={"primary"}
                                                style={{height: 40}}
                                                onClick={() =>
                                                {
                                                    setEditEmployee({
                                                        ...editEmployee,
                                                        salaryItems: filter(editEmployee.salaryItems,
                                                            (salaryItem, itemIndex) =>
                                                            {
                                                                return itemIndex !== index;
                                                            })
                                                    })
                                                }}
                                            >刪除</Button></Grid>
                                        </Grid>
                                    })}
                                    {editEmployee.salaryItems && <Grid item xs={8}>
                                        <b style={{margin: 20}}>薪資合計：${reduce(editEmployee.salaryItems, (acc, item) => (parseInt(item.amount) || 0) + acc, 0).toLocaleString()}</b>
                                    </Grid>}
                                </Grid>

                                <Grid item xs={8}>
                                    <b>勞健退投保級距</b>
                                </Grid>
                                <Grid item xs={8}>
                                    <EditTextField type={"number"} label={"勞工保險"}
                                                   setter={onChange("laborInsuranceAmount")}
                                                   value={editEmployee?.laborInsuranceAmount}/>
                                </Grid>
                                <Grid item xs={8}>
                                    <EditTextField type={"number"} label={"健保"}
                                                   setter={onChange("healthInsuranceAmount")}
                                                   value={editEmployee?.healthInsuranceAmount}/>
                                </Grid>
                                <Grid item xs={8}>
                                    <EditTextField type={"number"} label={"退休金"}
                                                   setter={onChange("pensionAmount")}
                                                   value={editEmployee?.pensionAmount}/>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormControlLabel label={"是否自提"}
                                        control={<Checkbox defaultChecked={employee?.pickup > 0} onChange={({target})=>{
                                            setPickupEnabled(target.checked);
                                            if (!target.checked) {
                                                setEditEmployee({...editEmployee, pickup:0});
                                            }
                                        }}/>}/>
                                    <EditTextField disabled={!pickupEnabled} type={"number"} label={"自提%數"}
                                                   setter={onChange("pickup")}
                                                   value={editEmployee?.pickup}/>
                                </Grid>


                                <Grid item xs={8}>
                                    <EditTextField multiline label={"說明"}
                                                   setter={onChange("description")}
                                                   value={editEmployee?.description}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction={"row"} border={1} borderTop={0}>
                            <Grid borderRight={1} borderBottom={1} style={{
                                background: "#fab507", display: "flex",
                                alignItems: "center", justifyContent: "center", width: 80, height: 60
                            }}>
                                眷屬資料
                            </Grid>
                            <Grid item container spacing={2} xs={10} margin={2}>

                                <Grid item xs={2} alignItems={"center"} display={"flex"}> <Button
                                    variant={"contained"}
                                    color={"primary"}
                                    onClick={() =>
                                    {
                                        const newItem = {};
                                        const newDependents = editEmployee.dependents ? [...editEmployee.dependents, {}] : [newItem];
                                        setEditEmployee({...editEmployee, dependents: newDependents});
                                    }}
                                >新增眷屬</Button></Grid>

                                <Grid item xs={12}>
                                    {editEmployee.dependents?.map((item, index) =>
                                    {
                                        return <Grid container>
                                            <Grid item container spacing={2} margin={1} paddingBottom={1}
                                                  paddingRight={1} alignItems={"center"} xs={10}
                                                  border={"1px solid"}>
                                                <Grid item xs={5}><TextField required fullWidth label={"姓名"}
                                                                             value={item.name}
                                                                             onChange={({target}) =>
                                                                             {
                                                                                 onDependentChange(index, "name", target?.value);
                                                                             }}/></Grid>
                                                <Grid item xs={5}>
                                                    <FormControl required>
                                                        <InputLabel>性別</InputLabel>
                                                        <Select fullWidth label={"性別"}
                                                                value={item.gender}
                                                                onChange={({target}) =>
                                                                {
                                                                    console.log(target);
                                                                    onDependentChange(index, "gender", target?.value);
                                                                }}>
                                                            <MenuItem value={GenderEnum.Female}>女</MenuItem>
                                                            <MenuItem value={GenderEnum.Male}>男</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={5}><TextField required fullWidth label={"關係"}
                                                                             value={item.relation}
                                                                             onChange={({target}) =>
                                                                             {
                                                                                 onDependentChange(index, "relation", target?.value);
                                                                             }}/></Grid>
                                                <Grid item xs={5}><TextField required fullWidth label={"國籍"}
                                                                             value={item.nationality}
                                                                             onChange={({target}) =>
                                                                             {
                                                                                 onDependentChange(index, "nationality", target?.value);
                                                                             }}/></Grid>
                                                <Grid item xs={5}><TextField required fullWidth label={"身分證字號"}
                                                                             value={item.idNumber}
                                                                             onChange={({target}) =>
                                                                             {
                                                                                 onDependentChange(index, "idNumber", target?.value);
                                                                             }}/></Grid>
                                                <Grid item xs={5}>
                                                    <TextField required fullWidth type={"date"} label={"生日"}
                                                               value={item.birthday ? format(new Date(item.birthday), "yyyy-MM-dd") : null}
                                                               InputProps={{startAdornment: <></>}}
                                                               onChange={({target}) =>
                                                               {
                                                                   onDependentChange(index, "birthday", target?.value);
                                                               }}/></Grid>
                                            </Grid>
                                            <Grid item xs={1}><Button
                                                variant={"contained"}
                                                color={"primary"}
                                                style={{height: 40}}
                                                onClick={() =>
                                                {
                                                    setEditEmployee({
                                                        ...editEmployee,
                                                        dependents: filter(editEmployee.dependents,
                                                            (dependent, itemIndex) =>
                                                            {
                                                                return itemIndex !== index;
                                                            })
                                                    })
                                                }}
                                            >刪除</Button></Grid>
                                        </Grid>
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>

                    </Card>
                </DialogContent>
                <DialogActions style={{paddingLeft: 24, paddingRight: 24}}>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        type={"submit"}
                    >
                        儲存
                    </Button>
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={() =>
                        {
                            setOpen(false);
                        }}
                    >
                        取消
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
        <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => setOpen(true)}
        >
            {action}
        </Button>
    </div>


}