import React from 'react';
import {Button} from "@mui/material";
import {Link} from "react-router-dom";



// eslint-disable-next-line @typescript-eslint/ban-types
export class ErrorBoundary extends React.Component<{},{hasError:boolean,error:any}> {
    constructor(props) {
        super(props);
        this.state = { hasError: false,error:null };
        window.onerror = (error)=>
        {
            console.error(`uncaught error`,error);
            this.setState({hasError:true,error});
        }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        //logErrorToMyService(error, errorInfo);
        //TODO: log this to google logging??
        console.error(error);
        console.error(errorInfo);
        this.setState({error});
    }

    render() {
        if (this.state.hasError) {
            return <div>
                <h1>不好意思，遇到問題了</h1>
            <pre>
            {this.state.error && this.state.error.message}
            </pre>
            <Button variant={"contained"} color={"primary"} onClick={()=>this.setState({hasError:false})}>
                <Link to={"/"}>
                    Go to startpage
                </Link>
            </Button>
            <Button variant={"contained"} color={"primary"} onClick={()=>this.setState({hasError:false})}>
               回去
            </Button>

            </div>;
        }

        return this.props.children;
    }
}
