import React, {useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl, Grid, InputLabel,
    MenuItem, Select,
    TextField,
    Typography
} from "@mui/material";
import {TaxClassificationEnum, useUpdateContractorMutation} from "../generated/graphql";
import {NoInsuranceMap, TaxClassificationMap} from "../common/Constant";
import UploadFile from "../common/UploadFile";
import {getAccessToken} from "../auth/Auth";
import NotificationPopup from "../common/NotificationPopup";
import {pick} from "lodash";
import theme from "../theme/Theme";

const UploadFileView = ({fileId, title, onSuccess}) => {
    return <div>
        {title}：
        {fileId && <Grid container direction={"column"} style={{padding: 10}}>
            <img style={{maxWidth: "250px", marginBottom: 10}}
                 src={`${process.env.REACT_APP_BACKEND_URI}/files/${fileId}` + `?token=${getAccessToken()}`}/>
        </Grid>}
        <UploadFile
            uploadTitle={`上傳${title}`}
            onSuccess={(res) => {
                if (res && res.length > 0) {
                    onSuccess(res[0].id)
                }
            }}/>
    </div>
}

export default ({contractor}) => {
    const [open, setOpen] = useState(false);
    const [editContractor, setEditContractor] = useState(contractor)
    const idTitle = editContractor.taxClassification == TaxClassificationEnum.Citizen ? "身分證" : "護照";
    const [updateContractor] = useUpdateContractorMutation()
    const onUpdate = async (contractor, onSuccess) => {
        try {
            if ("noInsurance" in contractor) {
                contractor.noInsurance = !!contractor.noInsurance;
            }
            const contractorDto = pick(contractor, [
                "name",
                "address",
                "email",
                "phone",
                "idNumber",
                "taxClassification",
                "contactAddress",
                "noInsurance",
                "bankCode",
                "bankAccountNumber",
                "bankAccountName",
                "passbookFileId",
                "idBackFileId",
                "idFrontFileId",
                "nationality",
            ])

            await updateContractor({
                variables: {
                    contractor: contractorDto,
                    id: contractor.id,
                },
                refetchQueries: ["getContractors"],
            });
            NotificationPopup.success(`儲存成功`);
            onSuccess();
        } catch (e: any) {
            NotificationPopup.error(`發生問題: ${e.message}`);
            console.error(e);
        }
    }
    return <div>
        <Dialog open={open} fullWidth={true}>
            <DialogTitle style={{backgroundColor: theme.palette.primary.dark}}>
                <Typography variant="h4" align="center">
                    修改廠商
                </Typography>
            </DialogTitle>
            <DialogContent>
                {[
                    {key: "name", title: "姓名"},
                    {key: "phone", title: "電話"},
                    {key: "address", title: "戶籍地址"},
                    {key: "contactAddress", title: "通訊地址"},
                    {key: "email", title: "Email"},
                    {
                        key: "taxClassification", title: "國籍", items: [
                            {value: TaxClassificationEnum.Citizen, title: TaxClassificationMap.citizen},
                            {value: TaxClassificationEnum.ResidentAlien, title: TaxClassificationMap.residentAlien},
                            {
                                value: TaxClassificationEnum.NonResidentAlien,
                                title: TaxClassificationMap.nonResidentAlien
                            }]
                    },
                    {key: "nationality", title: "國家"},
                    {key: "idNumber", title: `${idTitle}號碼`},
                    {
                        key: "noInsurance", title: "免扣二代健保", items: [
                            {value: 0, title: NoInsuranceMap.no},
                            {value: 1, title: NoInsuranceMap.yes},
                        ]
                    },
                    {key: "bankCode", title: "銀行代碼"},
                    {key: "bankAccountNumber", title: "銀行帳號"},
                    {key: "bankAccountName", title: "帳戶名稱"},


                ].map((field) => {
                    if (field.key == "taxClassification" || field.key == "noInsurance") {
                        const value = field.key == "noInsurance" ? editContractor.noInsurance !== null
                            ? editContractor.noInsurance ? 1 : 0 : null : editContractor[field.key] || ""
                        return (
                            <FormControl style={{marginTop: 10, width: "100%"}}>
                                <InputLabel>{field.title}</InputLabel>
                                <Select label={field.title} labelId="test-select-label" variant={"outlined"}
                                        onChange={({target}) => {
                                            setEditContractor({...editContractor, [field.key]: target.value})
                                        }} value={value}>
                                    {field.items?.map((item) => {
                                        return <MenuItem value={item.value}>{item.title}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        )
                    }
                    if (field.key == "nationality") {
                        if (editContractor.taxClassification == TaxClassificationEnum.Citizen) {
                            return null;
                        }
                    }
                    return (
                        <TextField
                            label={field.title}
                            variant={"outlined"}
                            style={{width: "100%", marginTop: 10}}
                            value={editContractor[field.key] || ""}
                            onChange={({target}) => {
                                setEditContractor({...editContractor, [field.key]: target.value})
                            }}/>
                    )
                })}
                <UploadFileView
                    fileId={editContractor.idFrontFileId || editContractor.idFrontFile?.id} title={`${idTitle}正面`} onSuccess={(fileId) =>
                    setEditContractor({...editContractor, idFrontFileId: fileId})}/>
                {
                    (editContractor.taxClassification == TaxClassificationEnum.Citizen) && <UploadFileView
                        fileId={editContractor.idBackFileId || editContractor.idBackFile?.id} title={`${idTitle}反面`} onSuccess={(fileId) =>
                        setEditContractor({...editContractor, idBackFileId: fileId})}/>
                }
                <UploadFileView
                    fileId={editContractor.passbookFileId || editContractor.passbookFile?.id} title={`存摺正面`} onSuccess={(fileId) =>
                    setEditContractor({...editContractor, passbookFileId: fileId})}/>

            </DialogContent>
            <DialogActions style={{paddingLeft: 24, paddingRight: 24}}>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    type={"submit"}
                    onClick={() => {
                        // console.log(editContractor)
                        onUpdate(editContractor, ()=>{
                            setOpen(false);
                        })

                    }}
                >
                    儲存
                </Button>
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    取消
                </Button>
            </DialogActions>
        </Dialog>
        <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => setOpen(true)}
        >
            修改
        </Button>

    </div>
}