import { ReactNotificationOptions, store } from "react-notifications-component";

const defaultOptions: ReactNotificationOptions = {
    insert: "top",
    container: "top-center",
    width: 250,
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
        duration: 2000,
        onScreen: false,
    },
};

export default {
    info(message: any) {
        store.addNotification({
            title: "INFO",
            message,
            type: "info",
            ...defaultOptions,
        });
    },
    success(message: any) {
        store.addNotification({
            title: "SUCCESS",
            message,
            type: "success",
            ...defaultOptions,
        });
    },
    error(message: any) {
        store.addNotification({
            title: "ERROR",
            message,
            type: "danger",
            ...defaultOptions,
            dismiss: {
                duration: 0,
                onScreen: false,
            },
        });
    },
};
