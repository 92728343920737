import {getAccessToken, useUser} from "../auth/Auth";
import {Box, Button, Grid, MenuItem, Select} from "@mui/material";
import {Authorization, Company, RoleEnum, useGetConfigQuery, useUpsertConfigMutation} from "../generated/graphql";
import {first} from "lodash";
import {useNavigate} from "react-router-dom";
import Page from "../common/Page";
import {useContext, useEffect, useState} from "react";
import {DataGridPro, GridColumns} from "@mui/x-data-grid-pro";
import {checkAlert, DataGridLocale, hasRole} from "../common/Utils";
import UpdateReportDescriptionButton from "./UpdateReportDescriptionButton";
import {CompanyContext} from "../routes";

const columns: GridColumns = [
    // {field: "id", headerName: "", width: 50, sortable: false},
    {field: "title", headerName: "財務報表項目", width: 300, sortable: false, flex: 5},
    {
        field: "uri", headerName: "", width: 80, sortable: false, flex: 1, align: "center",
        renderCell: (params) => {
            return <a
                href={params.value ? `${params.value}?token=${getAccessToken()}` : "#"}
                download={params.row.filename}
                style={{textDecoration: "none"}}
            >
                <Button
                    variant={"contained"}
                    color={"primary"}
                    disabled={!params.value}
                >
                    下載
                </Button>
            </a>
        }
    },
];

export default () => {
    const descriptionQuery = useGetConfigQuery({variables: {key: "report_description"}});
    const exampleQuery = useGetConfigQuery({variables: {key: "report_example"}});
    const user = useUser();
    const rows1 = [
        {id: 1, title: "日記簿", uri: null, filename: null},
        {id: 2, title: "分類帳", uri: null, filename: null},
        {id: 3, title: "資產負債表", uri: null, filename: null},
        {id: 4, title: "資產負債表(上月底)", uri: null, filename: null},
    ];
    const rows2 = [
        {id: 5, title: "綜合損益表(月)", uri: null, filename: null},
        {id: 6, title: "綜合損益表(月-上月底)", uri: null, filename: null},
        {id: 7, title: "綜合損益表(年)", uri: null, filename: null},
        {id: 8, title: "綜合損益表-月份比較", uri: null, filename: null},
    ];
    const company = useContext(CompanyContext);
    checkAlert(company, Authorization.Finance);
    company?.reports?.map((report) => {
        const mapFuntion = (row) => {
            if (report.name.includes(`${row.title}.XLS`)) {
                row.uri = report.uri;
                row.filename = report.name;
            }
        }
        rows1.map(mapFuntion)
        rows2.map(mapFuntion)
    });
    return (
        <Page title={"report page"} style={{padding: 20}}>
            <Box style={{minHeight: "40vh", marginTop: 20}}>
                <Grid container direction={"row"} spacing={2}>
                    <Grid item xs={6}>
                        <DataGridPro
                            disableMultipleSelection={true}
                            disableSelectionOnClick={true}
                            disableColumnMenu={true}
                            disableMultipleColumnsSorting={true}
                            disableColumnSelector={true}
                            disableColumnResize={true}
                            autoHeight={true}
                            hideFooter={true}
                            columns={columns}
                            rows={rows1}/>
                    </Grid>
                    <Grid item xs={6}>
                        <DataGridPro
                            disableMultipleSelection={true}
                            disableSelectionOnClick={true}
                            disableColumnMenu={true}
                            disableMultipleColumnsSorting={true}
                            disableColumnSelector={true}
                            disableColumnResize={true}
                            autoHeight={true}
                            hideFooter={true}
                            columns={columns}
                            rows={rows2}/>
                    </Grid>
                </Grid>
            </Box>
            <Grid container direction={"row"}>
                <Grid container item xs={6} alignItems={"center"} style={{paddingLeft: 20}}>
                    <div>
                        {hasRole(user, [RoleEnum.Admin]) && !descriptionQuery.loading &&
                        <UpdateReportDescriptionButton
                            config={descriptionQuery?.data?.config ?? {key: "report_description", value: "請自行輸入"}} title="修改備註"/>}
                        <b style={{lineHeight: 2, whiteSpace: "pre-wrap"}}>
                            {descriptionQuery?.data?.config?.value}
                        </b>
                    </div>
                </Grid>
                <Grid container item xs={6} alignItems={"center"} style={{padding: 40}}>
                    <div>
                        {hasRole(user, [RoleEnum.Admin]) && !exampleQuery.loading &&
                        <UpdateReportDescriptionButton
                            config={exampleQuery?.data?.config ?? {key: "report_example", value: "請自行輸入"}} title="修改範例"/>}
                        <b style={{lineHeight: 1.8, whiteSpace: "pre-wrap"}}>
                            {exampleQuery?.data?.config?.value}
                        </b>

                    </div>
                </Grid>
            </Grid>
        </Page>
    )
}